import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentHeaderColoredDefaultVariants = {}
const cmsContentHeaderColoredCompoundVariants = []

const cmsContentHeaderColoredSlotNames = [
  [
    "container",
    "cms-header-colored__container"
  ],
  [
    "top",
    "cms-header-colored__top"
  ],
  [
    "linkBox",
    "cms-header-colored__linkBox"
  ]
]
const cmsContentHeaderColoredSlotFns = /* @__PURE__ */ cmsContentHeaderColoredSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentHeaderColoredDefaultVariants, getSlotCompoundVariant(cmsContentHeaderColoredCompoundVariants, slotName))])

const cmsContentHeaderColoredFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentHeaderColoredSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentHeaderColoredVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentHeaderColoredDefaultVariants, ...compact(variants) })

export const cmsContentHeaderColored = /* @__PURE__ */ Object.assign(cmsContentHeaderColoredFn, {
  __recipe__: false,
  __name__: 'cmsContentHeaderColored',
  raw: (props) => props,
  variantKeys: cmsContentHeaderColoredVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentHeaderColoredVariantKeys)
  },
  getVariantProps
})